import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import "./StartTest.css";
import { Alert, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import parse from "html-react-parser";
import { startCandidateAssessment } from "../../Helpers/AsyncCalls";
import dayjs from "dayjs";

const StartTest = ({
  user,
  setIsStarted,
  loading,
  assessmentDetails,
  assessmentType,
  roleAssessmentMappingId
}) => {
  const [permissionError, setPermissionError] = useState(false);
  const [cameraAccessGranted, setCameraAccessGranted] = useState(false);
  const [timeUntilStart, setTimeUntilStart] = useState(null);
  const [timeFrameRemaining, setTimeFrameRemaining] = useState(null);
  const [isExpired, setIsExpired] = useState(false);
  const [isCalculatingTime, setIsCalculatingTime] = useState(true);

  useEffect(() => {
    const handleCameraAccess = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setCameraAccessGranted(true);
      } catch (error) {
        console.error("Error accessing media devices:", error);
        setPermissionError(true);
      }
    };
    handleCameraAccess();
  }, []);

  useEffect(() => {
    setIsCalculatingTime(true);

    if (!assessmentDetails.startTime) {
      setTimeUntilStart(0);
      setTimeFrameRemaining(null);
      setIsExpired(false);
      setIsCalculatingTime(false);
      return;
    }

    const calculateTimes = () => {
      const currentTime = dayjs();
      const startTime = dayjs(assessmentDetails.startTime);
      const endTime = startTime.add(2, 'minute');

      if (currentTime.isBefore(startTime)) {
        const waitingTime = startTime.diff(currentTime, 'second');
        setTimeUntilStart(waitingTime);
        setTimeFrameRemaining(null);
        setIsExpired(false);
      } else if (currentTime.isBefore(endTime)) {
        setTimeUntilStart(0);
        const remainingWindow = endTime.diff(currentTime, 'second');
        setTimeFrameRemaining(remainingWindow);
        setIsExpired(false);
      } else {
        setTimeUntilStart(0);
        setTimeFrameRemaining(0);
        setIsExpired(true);
      }
    };
    calculateTimes();
    setIsCalculatingTime(false);

    const interval = setInterval(calculateTimes, 1000);

    return () => clearInterval(interval);
  }, [assessmentDetails.startTime]);

  const getButtonText = () => {
    if (isCalculatingTime) {
      return "Loading...";;
    }

    if (!assessmentDetails.startTime) {
      return "Start the test";
    }

    if (timeUntilStart > 0) {
      const minutes = Math.floor(timeUntilStart / 60);
      const seconds = timeUntilStart % 60;
      return `Test will start in ${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    } else if (timeFrameRemaining > 0) {
      const minutes = Math.floor(timeFrameRemaining / 60);
      const seconds = timeFrameRemaining % 60;
      return `Start the test (Window closes in ${minutes}:${seconds < 10 ? "0" : ""}${seconds})`;
    } else {
      return "Test window has expired";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsStarted(true);
    if (assessmentType !== "audio") {
      startCandidateAssessment(user.id, roleAssessmentMappingId);
    }
  };
  const isButtonDisabled = () => {
    if (isCalculatingTime) {
      return true;
    }
    if (!assessmentDetails.startTime) {
      return !cameraAccessGranted || permissionError;
    }

    return !cameraAccessGranted || timeUntilStart > 0 || isExpired || permissionError;
  };

  return (
    <>
      <CssBaseline />
      <Container
        maxWidth="xl"
        className="StartTestContainer p-0"
        sx={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <div className="p-3 shadow-md rounded-sm  bg-white md:p-10 md:w-f startTestBox">
            <h3 className="text-2xl font-medium pb-7 justify-center md:text-3xl ">
              {assessmentDetails?.title}
            </h3>
            <div className="text-left list-disc assessment-description">
              {parse(assessmentDetails?.description)}
            </div>

            <h3 className="my-3 font-semibold text-left text-gray-500">
              Verify the following details are correct. Grant access to your
              camera, and then proceed by clicking the 'Start the test' button.
            </h3>
            <form action="#" className="w-full mt-3 flex flex-col gap-2 ">
              <div className="w-full mt-3 flex flex-col gap-2 md:flex-1 md:flex-row">
                <TextField
                  className="w-full"
                  id="name"
                  label="Name"
                  name="name"
                  variant="filled"
                  value={user?.firstName + " " + user?.lastName}
                  disabled={true}
                />
                <TextField
                  className="w-full"
                  id="email"
                  label="Email"
                  name="email"
                  variant="filled"
                  value={user?.email}
                  type="email"
                  disabled={true}
                />
              </div>
              <div>
                {permissionError ? (
                  <Alert severity="error">
                    Please allow access to the camera and microphone to start
                    the test.
                  </Alert>
                ) : (
                  <button
                    disabled={isButtonDisabled()}
                    onClick={handleSubmit}
                    className="transition-all w-full px-5 py-3 bg-purple-500 mt-4 font-bold text-lg text-white hover:bg-purple-700 disabled:hover:bg-purple-300 disabled:bg-purple-300 relative"
                  >
                    {isCalculatingTime ? (
                      <div className="flex items-center justify-center gap-2">
                        <CircularProgress size={20} color="inherit" />
                      </div>
                    ) : (
                      getButtonText()
                    )}
                  </button>
                )}
              </div>
            </form>
          </div>
        )}
      </Container>
    </>
  );
};

export default StartTest;
