import { Button } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import './Questionnaire.css'
import Timer from '../Utils/Timer';
import CircularProgress from '@mui/material/CircularProgress';
import { gradeCandidateAssessment, getCandidateResults, postCandidateResults, postCandidateMediaAnswer, editCandidateMediaTextAnswer } from '../../Helpers/AsyncCalls';
import LoadingButton from '@mui/lab/LoadingButton';
import RecordingQue from './QuestionTypes/RecordingQue';
import SingleChoiceQue from './QuestionTypes/SingleChoiceQue';
import MultipleChoiceQue from './QuestionTypes/MultipleChoiceQue';
import TextFieldQue from './QuestionTypes/TextFieldQue';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';

const Questionnaire = ({ user, questionnaireData, setCandidateResults, assessmentDetails, assessmentType, roleAssessmentMappingId }) => {

    const [questionNo, setQuestionNo] = useState(0);
    const [loading, setloading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);

    //tmak to capture out of screen 
    const [cursorOutOfScreenCount, setCursorOutOfScreenCount] = useState(0);
    //tmak to force full screen
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [candidateAnswerDetails, setCandidateAnswerDetails] = useState([]) //Array of Object = { candidateId, questionId , AnswerId , CandidateMediaAnswerId }
    // const [timer, setTimer] = useState(questionnaire[questionNo].time) //For particular question timing
    const navigate = useNavigate();
    const [isExitedFullScreen, setisExitedFullScreen] = useState(false);
    const [recordedAnswers, setRecordedAnswers] = useState([]);
    const audioRef = useRef(null);
    const [BASE64String, setBASE64String] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const [isVidRecording, setIsVidRecording] = useState(false);
    //tmak to force full screen
    useEffect(() => {
        const element = document.documentElement; // Fullscreen the entire page

        const requestFullscreen = () => {
            if (element && element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element && element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element && element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element && element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
        };

        const handleClick = () => {
            requestFullscreen();
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    useEffect(() => {
        const handleFullScreenChange = () => {
            const newIsFullScreen = !!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement);
            if (isFullScreen && !isExitedFullScreen) {
                setisExitedFullScreen(true);
            }
            setIsFullScreen(newIsFullScreen);
        };
        // Event listener for beforeunload to warn candidates
        const confirmationMessage = 'Are you sure you want to leave? If you refresh or close this page, your current progress will be lost, and you will not be able to resume the assessment.';
        const beforeUnloadListener = (event) => {
            event.preventDefault();
            event.returnValue = confirmationMessage;
            return confirmationMessage;
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('msfullscreenchange', handleFullScreenChange);
        window.addEventListener('beforeunload', beforeUnloadListener);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('msfullscreenchange', handleFullScreenChange);
            window.removeEventListener('beforeunload', beforeUnloadListener);
        };
    }, [isFullScreen]);

    useEffect(() => {
        setSelectedOptions(new Array(questionnaireData.length).fill(-1))
    }, [questionnaireData])

    useEffect(() => {
        const handleMouseLeave = (e) => {
            if (e.clientY <= 0 || e.clientX <= 0 || e.clientX >= window.innerWidth || e.clientY >= window.innerHeight) {
                handleCursorLeave();
            }
        };

        document.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            document.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [cursorOutOfScreenCount]);


    useEffect(() => {
        if (questionNo > 0 && questionnaireData[questionNo - 1].type === 'text') {
            let ans = candidateAnswerDetails[questionNo - 1]?.CandidateMediaAnswerId;
            if (ans) {
                //Put
                editCandidateMediaTextAnswer(ans, selectedOptions[questionNo - 1]);
            }
            else {
                //Post
                const postTextAns = async () => {
                    const res = await postCandidateMediaAnswer(selectedOptions[questionNo - 1], null);
                    const qId = questionnaireData[questionNo - 1].id;

                    const currentQuestionAnswerDetails = {
                        roleAssessmentMappingId: roleAssessmentMappingId,
                        QuestionId: qId,
                        AnswerId: null,
                        CandidateMediaAnswerId: res.id,
                        CandidateId: user.id
                    }

                    let currentAnswerDetails = candidateAnswerDetails;
                    currentAnswerDetails[questionNo - 1] = currentQuestionAnswerDetails;
                    setCandidateAnswerDetails(currentAnswerDetails);
                }
                if (selectedOptions[questionNo - 1].length > 0) {
                    postTextAns();
                }
            }
        }
    }, [questionNo, candidateAnswerDetails, questionnaireData, user.id])

    useEffect(() => {
        setBASE64String(questionnaireData[questionNo]?.audioQuestion?.questionData);
    }, [questionNo, questionnaireData])
    useEffect(() => {

        if (audioRef.current) {
            audioRef.current.src = `data:audio/wav;base64,${BASE64String}`;

        }
    }, [BASE64String]);
    //For timing of a particular function

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (timer <= 0) {
    //             handleNext()
    //         }
    //         else {
    //             setTimer(prevTimer => prevTimer - 1);
    //         }
    //     }, 1000);

    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [timer]);


    useEffect(() => {
        if (assessmentType !== "audio" && questionnaireData.length > 0) {
            let initialAnswers = questionnaireData.map((q) => {
                return {
                    roleAssessmentMappingId: roleAssessmentMappingId,
                    QuestionId: q.id,
                    CandidateId: user.id,
                    AnswerId: null,
                    CandidateMediaAnswerId: null,
                }
            })
            setCandidateAnswerDetails(initialAnswers);

        }
    }, [questionnaireData, assessmentType])


    //tmak to capture out of screen
    const handleCursorLeave = () => {
        setCursorOutOfScreenCount(cursorOutOfScreenCount + 1);
    };

    const handleNext = async () => {
        //reset the timer when candidate moves to the next question
        // setTimer(questionnaire[questionNo].time)
        if ((questionNo + 1) < questionnaireData.length) {
            setQuestionNo(questionNo + 1)
        }
        else {
            // Submitting the test
            if (assessmentType !== "audio") {
                handleTestSubmit();
            }
            else {
                handleTestSubmit();
                navigate('/endtest')
            }
        }
    }

    const handlePrevious = () => {
        setQuestionNo(questionNo - 1)
    }

    function isLastQuestion() {
        return questionNo === questionnaireData.length - 1
    }

    //Function to get single type answers and save it into selectedOptions array
    const getSingleTypeAnswer = (optionSelected, aId) => {
        let optionSelection = [...selectedOptions];
        optionSelection[questionNo] = optionSelected;
        setSelectedOptions(optionSelection);

        const qId = questionnaireData[questionNo].id;

        const currentQuestionAnswerDetails = {
            roleAssessmentMappingId: roleAssessmentMappingId,
            QuestionId: qId,
            AnswerId: aId,
            CandidateMediaAnswerId: null,
            CandidateId: user.id

        }
        let currentAnswerDetails = candidateAnswerDetails;
        currentAnswerDetails[questionNo] = currentQuestionAnswerDetails;
        setCandidateAnswerDetails(currentAnswerDetails);
    }

    //Function to get Multiple type of answers
    //argument takes array of multiple types
    const getMultipleTypeAnswer = (optionsSelected, currQuestionNo) => {
        let optionSelection = [...selectedOptions];
        optionSelection[currQuestionNo] = optionsSelected;
        setSelectedOptions(optionSelection);

        let selectedAnswerIds = [];

        for (let i = 0; i < questionnaireData[currQuestionNo].answers.length; i++) {
            if (optionsSelected.includes(i)) {
                selectedAnswerIds.push(questionnaireData[currQuestionNo].answers[i].id)
            }
        }
        const qId = questionnaireData[currQuestionNo].id;
        const currentQuestionAnswerDetails = {
            roleAssessmentMappingId: roleAssessmentMappingId,
            QuestionId: qId,
            AnswerId: selectedAnswerIds,
            CandidateMediaAnswerId: null,
            CandidateId: user.id


        }
        let currentAnswerDetails = candidateAnswerDetails;
        currentAnswerDetails[currQuestionNo] = currentQuestionAnswerDetails;
        setCandidateAnswerDetails(currentAnswerDetails);

    }

    //Function to get text field answer
    //arg takes text field with mediaAnsId
    const getTextFieldAnswer = (value) => {
        let tempValue = value.trim();
        let optionSelection = [...selectedOptions];
        optionSelection[questionNo] = tempValue;
        setSelectedOptions(optionSelection);
    }

    //Get call from recording child if the user submitted the video answer for the question
    const getCallFromRecComponent = (cMediaAnswerId) => {
        let optionSelection = [...selectedOptions];
        optionSelection[questionNo] = true;
        setSelectedOptions(optionSelection);

        const qId = questionnaireData[questionNo].id;
        const currentQuestionAnswerDetails = {
            roleAssessmentMappingId: roleAssessmentMappingId,
            QuestionId: qId,
            AnswerId: null,
            CandidateMediaAnswerId: cMediaAnswerId,
            CandidateId: user.id

        }

        let currentAnswerDetails = candidateAnswerDetails;
        currentAnswerDetails[questionNo] = currentQuestionAnswerDetails;
        setCandidateAnswerDetails(currentAnswerDetails);
    }

    const handleTestSubmit = async () => {
        setloading(true);
        await postCandidateResults(user.id, candidateAnswerDetails, roleAssessmentMappingId, assessmentType ? true : false)
        // await gradeCandidateAssessment(user.id, questionnaire.id, cursorOutOfScreenCount, !isFullScreen, selectedOptions);
        if (assessmentType !== "audio") {
            await gradeCandidateAssessment(user.id, roleAssessmentMappingId, cursorOutOfScreenCount, isExitedFullScreen)
            let results = await getCandidateResults(user.id, roleAssessmentMappingId);
            setCandidateResults(results);
        }
    };

    const savePreviousRecAnswers = (blob, questionNo) => {
        const stringQuestionNo = questionNo.toString();

        const existingEntryIndex = recordedAnswers.findIndex(
            entry => Object.keys(entry)[0] === stringQuestionNo
        );

        if (existingEntryIndex !== -1) {
            const updatedRecordedAnswers = [...recordedAnswers];
            const existingQuestion = updatedRecordedAnswers[existingEntryIndex];
            const existingQuestionNo = Object.keys(existingQuestion)[0];
            const existingBlobs = existingQuestion[existingQuestionNo];

            existingBlobs.push(blob);
            setRecordedAnswers(updatedRecordedAnswers);
        } else {
            const newEntry = { [questionNo]: [blob] };

            setRecordedAnswers([...recordedAnswers, newEntry]);
        }
    }

    const blobsForQuestionNo = recordedAnswers.find(
        entry => Object.keys(entry)[0] === questionNo.toString()
    )?.[questionNo];

    const handlePlay = () => {
        setIsPlaying(true);
    };

    const stopPlaying = () => {
        setIsPlaying(false);
    }

    const getIsVideoRecording = (isRecording) => {
        setIsVidRecording(isRecording);
        if (isRecording && assessmentType && BASE64String) {
            audioRef.current.play();
        }
    }

    return (
        <div className=''>
            <header className='bg-white mt-4'>
                <div className="nextBtn p-4 flex justify-between">
                    <Button disabled={(!questionNo || loading) || (assessmentType === "audio" && isVidRecording)} onClick={handlePrevious} variant="contained" size="medium" className='w-36 h-12 self-center' startIcon={<><NavigateBeforeIcon /></>}>
                        Previous
                    </Button>
                    <div className='flex flex-col w-full justify-center'>
                        <h3 className='text-2xl self-center font-semibold md:text-2xl '>{assessmentDetails?.title}</h3>
                        <div className=''>
                            <span className='text-sm text-blue-500 roboto'><b><span>Question</span>: {questionNo + 1}</b> of <b>{questionnaireData.length}</b></span>
                            {assessmentDetails?.time &&
                                <Timer min={assessmentDetails?.time} hours={0} handleTestSubmit={handleTestSubmit} />

                            }
                            {/* Question Timer: {timer} seconds */}
                        </div>
                    </div>
                    <LoadingButton
                        disabled={assessmentType === "audio" && !candidateAnswerDetails[questionNo]}
                        color="primary"
                        loading={loading}
                        loadingPosition="center"
                        onClick={handleNext}
                        variant="contained"
                        size="medium"
                        className={`w-36 h-12 self-center`}
                        endIcon={!loading && (!isLastQuestion() ? <NavigateNextIcon /> : <ExitToAppIcon />)}>
                        {
                            !isLastQuestion() ? 'Next' : 'End Test'
                        }
                    </LoadingButton>
                </div>
            </header>
            <div className="mt-4 shadow-md rounded-sm bg-white overflow-scroll-hidden ">
                {(questionnaireData.length > 0 && questionnaireData[questionNo]) ? (
                    <div>
                        <div className='flex flex-row space-x-12'>
                            <div className="disable-copy-paste w-1/2 my-4 ml-4" onContextMenu={(e) => e.preventDefault()}>
                                {assessmentType === "audio" ? <>
                                    <h4 className='text-xl self-center font-semibold mb-4'>Audio Question</h4>
                                    <div className='flex items-center flex-col justify-center  w-full'
                                    >
                                        <ol className='flex flex-col gap-2 pb-12 px-8 text-left mt-4 list-decimal' >
                                            <h1 className='text-xl text-red-700 font-bold '>Guidelines</h1>
                                            <li className='font-semibold text-red-500'>Once the play button is clicked, the video recording will commence to capture your response.
                                            </li>
                                            <li className='font-semibold text-red-500'>You have the option to listen to the audio multiple times for clarity and understanding. However, please note that your video will remain static during this process to ensure a fair assessment.
                                            </li>
                                        </ol>
                                        {BASE64String && !candidateAnswerDetails[questionNo] && (
                                            <>
                                                <audio controls className='w-full pl-10' autoPlay={isVidRecording} onPlay={handlePlay} ref={audioRef} >
                                                    <source src={`data:audio/wav;base64,${BASE64String}`} type="audio/wav" />
                                                    Your browser does not support the audio element.
                                                </audio>
                                                {
                                                    isVidRecording && <div className='p-12 w-full text-left font-semibold'>
                                                        <div className=' text-gray-600'
                                                        >
                                                            Q. {parse(questionnaireData[questionNo].text)}
                                                        </div>
                                                    </div>
                                                }
                                            </>

                                        )}
                                    </div>

                                </> : questionnaireData[questionNo].type === "video" ? <>
                                    <h4 className='text-xl self-center font-semibold mb-4'>Question</h4>
                                    <div className='text-l font-medium text-left text-gray-600'
                                    >
                                        <div className='font-semibold text-red-500 text-sm'>Once the play button is clicked, the question will be visible</div>
                                        {isVidRecording && <div className='mt-4'>{parse(questionnaireData[questionNo].text)}</div>}
                                    </div>
                                </> :
                                    <>
                                        <h4 className='text-xl self-center font-semibold mb-4'>Question</h4>
                                        <div className='text-l font-medium text-left text-gray-600'
                                        >
                                            {parse(questionnaireData[questionNo].text)}
                                        </div>
                                    </>
                                }
                            </div>

                            <div className="inline-block h-screen min-h-[1em] w-0.5 self-stretch bg-neutral-200 opacity-100 dark:opacity-50"></div>
                            <div className="flex flex-col gap-1 w-1/2 my-4">

                                {questionnaireData[questionNo]?.type === 'video' && <>
                                    <h4 className='text-xl self-center font-semibold mb-4'>Record Video</h4>
                                    {
                                        selectedOptions[questionNo] === true ? <>
                                            <h1 className='text-green-500'><b>
                                                Your video response has been saved!
                                            </b> </h1>
                                        </> : <>
                                            <b className=" text-red-500">The video duration should not exceed a maximum of 2 minutes</b>
                                            <span>Initiate the capture process and save the video to retain your response.</span>
                                            <RecordingQue getCallFromRecComponent={getCallFromRecComponent} question={questionnaireData[questionNo]} savePreviousRecAnswers={savePreviousRecAnswers} questionNo={questionNo} blobs={blobsForQuestionNo} getIsVideoRecording={getIsVideoRecording} assessmentType={assessmentType} />
                                        </>
                                    }
                                </>}
                                {questionnaireData[questionNo]?.type === 'single' && <>
                                    <h4 className='text-xl self-center font-semibold mb-4'>Answers</h4>
                                    <SingleChoiceQue question={questionnaireData[questionNo]} loading={loading} getSingleTypeAnswer={getSingleTypeAnswer} questionNo={questionNo} selectedAnswer={selectedOptions[questionNo]} />
                                </>}
                                {questionnaireData[questionNo]?.type === 'multiple' && <MultipleChoiceQue getMultipleTypeAnswer={getMultipleTypeAnswer} question={questionnaireData[questionNo]} loading={loading} selectedAnswers={selectedOptions[questionNo]} questionNo={questionNo} />}
                                {questionnaireData[questionNo]?.type === 'text' && <TextFieldQue getTextFieldAnswer={getTextFieldAnswer} selectedOptions={selectedOptions} questionNo={questionNo} candidateAnswerDetails={candidateAnswerDetails} />}

                                {questionnaireData[questionNo]?.type === 'audio' && <>
                                    <h4 className='text-xl self-center font-semibold mb-4'>Record Video</h4>
                                    {
                                        selectedOptions[questionNo] === true ? <>
                                            <h1 className='text-green-500'><b>
                                                Your video response has been saved!
                                            </b> </h1>
                                        </> : <>
                                            <div className=" text-red-500 mb-2 font-semibold">Only one submission is permitted.</div>
                                            <div className=" text-red-500 mb-2 font-semibold">Video submission will occur automatically at the two-minute mark.</div>
                                            <div className=" text-red-500 mb-10 font-semibold">Upon completion of your response, click "Stop Capturing" to save and submit the video.</div>
                                            <RecordingQue getCallFromRecComponent={getCallFromRecComponent} question={questionnaireData[questionNo]} savePreviousRecAnswers={savePreviousRecAnswers} questionNo={questionNo} blobs={blobsForQuestionNo} isPlaying={isPlaying} stopPlaying={stopPlaying} assessmentType={assessmentType} getIsVideoRecording={getIsVideoRecording} />
                                        </>
                                    }
                                </>}
                            </div>
                        </div>
                    </div>
                ) : <div className='flex flex-col items-center justify-center h-screen'>
                    <CircularProgress />
                </div>
                }
            </div>
        </div>
    )
}

export default Questionnaire
