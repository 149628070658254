import { server } from "./helpers";


export const getUser = async (userId) => {
    let res = await fetch (`${server.OurUrl}/api/Candidates/${userId}`)
    let json = await res.json()
    return json
}

export const getQuestionnaire = async (qId) =>{
    let res = await fetch(`${server.OurUrl}/api/AssessmentQuestionMapping/${qId}`);
    let json = await res.json()
    return json;
}

export const getCandidateResults = async (candidateId, qId) => {
    try {
        let res = await fetch(`${server.OurUrl}/api/Candidates/${candidateId}/results/${qId}`)
        let json = await res.json()
        return json    
    } catch (error) {
        return null
    }    
}

//Grade candidate Assessment
export const gradeCandidateAssessment = async (cId,roleAssessmentMappingId,countOfMouse,exitedFullScreen) => {
    await fetch(`${server.OurUrl}/api/AssessmentQuestionnaires/${roleAssessmentMappingId}/${cId}/${countOfMouse}/${exitedFullScreen}`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }, 
        // body: JSON.stringify(ans)
    })
}

export const startCandidateAssessment = async (cId, roleAssessmentMappingId) => {
    await fetch(`${server.OurUrl}/api/Candidates/${cId}/startAssessment/${roleAssessmentMappingId}`)
}


export const getAssessmentData = async (qId) =>{
    let res = await fetch(`${server.OurUrl}/api/RoleAssessmentMapping/${qId}`);
    let json = await res.json() 
    return json;
}

export const getAssessmentDataForAudio = async (qId) =>{
    let res = await fetch(`${server.OurUrl}/api/AssessmentQuestionnaires/${qId}`);
    let json = await res.json() 
    return json;
}

//Post Candidate Media Answer
export const postCandidateMediaAnswer = async (text,answerVideo) =>{
    const AnswerText = text; 
    const res = await fetch(`${server.OurUrl}/api/CandidateMediaAnswer`, {  
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }, 
        body: JSON.stringify({AnswerText,AnswerVideo:answerVideo})
    })
    let json = await res.json();
    return json;
}

//Post Candidate Results
export const postCandidateResults = async (cId,ans,roleAssessmentMappingId,isAudioAssessment) =>{
    
    const candidateAnswers=[];

    //Simplifying to pass as an CandidateAnswerDto List
    await ans.map((ca)=>{
        if(Array.isArray(ca.AnswerId)){
            for(let i of ca.AnswerId){
                candidateAnswers.push({
                    roleAssessmentMappingId:roleAssessmentMappingId,
                    AnswerId:i,
                    CandidateId:ca.CandidateId,
                    CandidateMediaAnswerId:ca.CandidateMediaAnswerId,
                    QuestionId:ca.QuestionId
                })
            }
        }
        else{
            candidateAnswers.push({
                roleAssessmentMappingId:roleAssessmentMappingId,
                AnswerId:ca.AnswerId,
                CandidateId:ca.CandidateId,
                CandidateMediaAnswerId:ca.CandidateMediaAnswerId,
                QuestionId:ca.QuestionId
            })
        }
    })

    await fetch(`${server.OurUrl}/api/CandidateAnswer/${cId}?isAudioAnswers=${isAudioAssessment}`, {  
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }, 
        body: JSON.stringify(candidateAnswers)
    })
}


//EDIT Candidate Media Answer Text
export const editCandidateMediaTextAnswer = async (Id,AnswerText) =>{
    await fetch(`${server.OurUrl}/api/CandidateMediaAnswer/${Id}`, {
        method: "PUT",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }, 
        body: JSON.stringify({Id,AnswerText,AnswerVideo:null})
    })
}

export const getCandidateAudioAnswers = async (candidateId,questionnareId) =>{

    let res = await fetch(`${server.OurUrl}/api/CandidateSpecificQuestionnaires/Answers?CandidateId=${candidateId}&assessmentQuestionnaireId=${questionnareId}`);
    let json = await res.json() 
    return json;
}